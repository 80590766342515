<template>
    <div class="hud-container col-2" v-if="scanParameters && conditions">
        <!-- <div v-for="n in 4">
        </div> -->
        <div class="title">
            {{ t('message.scan_quality') }}
        </div>
        <div>
            <span v-for="condition in conditions" :key="condition">
                <img :src="imgSrc(condition)" class="stars"/> 
            </span>
        </div>
    </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default
  {
    name: 'signal-quality-box',
    props: {
        scanParameters: null,
    },
    setup() {
        const { t, locale } = useI18n();
        return { t, locale }
    },
    mounted() {
        this.getConditions();
    },
    data() {
        return {
            conditions: null,
        }
    },
    watch: {
        scanParameters() {
            this.getConditions();
        }
    },
    methods: {
        imgSrc(condition) {
            if (condition) { return require("./assets/colour-star.svg"); }
            else { return require("./assets/grey-star.svg"); }
        },
        getConditions() {
            if (this.scanParameters !== null) {
                let numberOfTotalStars = 5;
                let numberOfGoodStars = Math.floor(this.scanParameters.signalQuality / 100 * numberOfTotalStars);
                let numberOfBadStars = numberOfTotalStars - numberOfGoodStars;
                let conditions = []
                for (let i=0; i<numberOfGoodStars; i++) { conditions.push(true) }
                for (let i=0; i<numberOfBadStars; i++) { conditions.push(false) }
                this.conditions = conditions
            } else {
                this.conditions = null;
            }
        }
    },
    computed: {
        parameters(){
            if (this.scanParameters && this.scanParameters.signalQuality) {
                return this.scanParameters.signalQuality.toString()
            }
            return "null"
        },
    }
}
</script>

<style scoped>
.stars {
    max-width: 40px;
}

.hud-container {
    width: 20%;
    min-width: 200px;
    height:150px;
    text-align: center;
    overflow: hidden;

    background: rgba(29, 27, 27, 0.7);
    backdrop-filter: blur(100px);
    border-radius: 10px;
}
.title {
    /* font-family: Inter; */
    width: fit-content;
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #F5B33B;
}
.data {
    margin-left: auto;
    margin-right: auto;
    width: max-content;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    max-width: 100%;
    
    color: #FFFFFF;
}
.coming-soon {
    margin-left: auto;
    margin-right: auto;
    width: max-content;
    font-style: italic;
    font-weight: 500;
    font-size: 32px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    max-width: 100%;

    color: #FFFFFF;    
}
.unit {
    position: absolute;
    bottom: 16px;
    right: 16px;
    width: 74.57px;
    height: 38.1px;

    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    display: flex;
    align-items: center;
    text-align: right;

    color: #FFFFFF;
}
.text-right {
    position: absolute;
    right: 0;
    margin-right: 0.1em;
}

/* for iPad and tablets */
@media (max-width: 1044px) {
    .hud-container {
        width: 180px;
        min-width: min-content;
        height: 150px;
        text-align: center;
        display: inline-block;
        overflow: break-word;

        background: rgba(29, 27, 27, 0.7);
        backdrop-filter: blur(100px);
        border-radius: 10px;
        margin: 0 5px;
    }
    .title {
        /* font-family: Inter; */
        width: 90%;
        height: 50%;
        margin-top: 8px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0px;
        font-weight: 600;
        font-size: x-large;
        line-height:normal;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #F5B33B;
    }
    .data {
        margin-left: auto;
        margin-right: auto;
        margin-top: 5px;
        width: fit-content;
        font-style: normal;
        font-weight: 500;
        font-size: x-large;
        line-height: normal;
        display: flex;
        align-items: center;
        text-align: center;
        max-width: 100%;
        
        color: #FFFFFF;
    }
    .coming-soon {
        margin-left: auto;
        margin-right: auto;
        width: max-content;
        font-style: italic;
        font-weight: 500;
        font-size: 12px;
        line-height: 58px;
        display: flex;
        align-items: center;
        text-align: center;
        max-width: 100%;

        color: #FFFFFF;    
    }
    .unit {
        position: absolute;
        bottom: 0px;
        right: 8px;
        width: 74.57px;
        height: 38.1px;

        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: right;

        color: #FFFFFF;
    }
    .text-right {
        position: absolute;
        right: 0;
        margin-right: 0.1em;
    }
    .stars{
        max-width: 30px;
    }
}

/* for mobile phone */
@media (max-width: 768px) {
    .hud-container {
        width: 150px;
        min-width: min-content;
        height: 120px;
        text-align: center;
        display: inline-block;
        overflow: break-word;

        background: rgba(29, 27, 27, 0.7);
        backdrop-filter: blur(100px);
        border-radius: 10px;
        margin: 0 5px;
    }
    .title {
        width: 90%;
        margin-top: 8px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0px;
        font-weight: 600;
        font-size: x-large;
        line-height:normal;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #F5B33B;
    }
    .data {
        margin-left: auto;
        margin-right: auto;
        margin-top: 5px;
        width: fit-content;
        font-style: normal;
        font-weight: 500;
        font-size: large;
        line-height: 15px;
        display: flex;
        align-items: center;
        text-align: center;
        max-width: 100%;
        
        color: #FFFFFF;
    }
    .coming-soon {
        margin-left: auto;
        margin-right: auto;
        width: max-content;
        font-style: italic;
        font-weight: 500;
        font-size: 12px;
        line-height: 58px;
        display: flex;
        align-items: center;
        text-align: center;
        max-width: 100%;

        color: #FFFFFF;    
    }
    .unit {
        position: absolute;
        bottom: 0px;
        right: 8px;
        width: 74.57px;
        height: 38.1px;

        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: right;

        color: #FFFFFF;
    }
    .text-right {
        position: absolute;
        right: 0;
        margin-right: 0.1em;
    }
    .stars{
        max-width: 20px;
    }
}

</style>


