<template>
    <div>
        <div class="content" v-if="currentUser && currentUser.currentUser.lastVitalSigns">
        <!-- <div class="content" v-if="currentUser && currentUser.currentUser.lastVitalSigns && profileFilled"> -->
            <div class="container-full font-sarabun">
                <!-- <div class="row mb-4 justify-content-end"> -->
                <div class="d-flex">
                    <!-- <feedback class="col-6" /> -->
                    <div class="col-4"></div>
                    <div class="col-8 email-button">
                        <feedback />
                    </div>
                </div>
                <!-- <div class="row justify-content-end mb-4">
                    <div class="col-2">
                        <img id="logo" src="./assets/mannings-logo.svg" />
                    </div>
                </div> -->
                <div class="mt-2 mb-4">
                    <h1 class="fw-bolder color-brown">
                        {{t('health_report.title')}}
                    </h1>
                </div>
                <div class="bg-light py-4 px-2 content-card fw-bold px-4 d-flex flex-row">
                    <div class="col-7 color-gray fs-5">
                        <div>
                            {{t('health_report.age')}}: {{ age }}
                        </div>
                        <div>
                            {{t('health_report.sex')}}: {{ currentUser!==undefined ? t('health_report.'+currentUser.currentUser.userSettings.gender) : ""}}
                        </div>
                    </div>
                    <div class="col-5">
                        <div class="d-flex flex-row color-gray align-items-center h-100">
                            <div class="col-6 pe-1 h-100">
                                <div class="general-score">
                                    <div class="text-center">
                                        {{t('health_report.wellness.stress.title')}}                                
                                    </div>
                                    <div class="justify-content-center fs-3 color-brown d-flex flex-row">
                                        <img src="./assets/stress-top.svg" alt="covid" class="ps-0 icon-profile-vitals">
                                        <div>
                                            {{ this.getData('stress').toFixed(1) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 ps-1 h-100">
                                <div class="general-score">
                                    <div class="text-center">
                                        {{t('health_report.general_wellness')}}
                                    </div>
                                    <div class="justify-content-center fs-3 color-brown d-flex flex-row">
                                        <img src="./assets/general-wellness.svg" alt="covid" class="ps-0 icon-profile-vitals">
                                        <div>
                                            {{ this.getData('generalWellness').toFixed(1) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Wellness -->
                <div class="bg-light content-card mt-5 justify-content-center">
                    <div class="px-3 py-2 bg-brown">
                        <h2 class="text-light fs-3 fw-bolder px-0 align-middle">{{t('health_report.wellness.title')}}</h2>
                    </div>

                    <!-- General Wellness -->
                    <div class="px-3 py-4 color-gray flex-row d-flex row gx-3">
                        <div class="col-8">
                            <div class="d-flex flex-row justify-content-end">
                                <div class="align-self-start">
                                    <img src="./assets/general-wellness-2.svg" alt="" class="pe-0 float-end icon-vital-signs">
                                </div>
                                <div class="col ps-3">
                                    <div class="border-gray fw-bolder fs-5">{{t('health_report.wellness.general_wellness.title')}}</div>
                                    <div class="text-justify">
                                        <p class="px-0">{{t('health_report.wellness.general_wellness.definition')}}</p>
                                    </div>

                                    <div v-if="te('health_report.wellness.general_wellness.measurement')">
                                        <div class="border-gray fw-bolder fs-5">{{t('health_report.how_measured')}}</div>
                                        <div class="text-justify">
                                            <p class="px-0">{{t('health_report.wellness.general_wellness.measurement')}}</p>
                                        </div>
                                    </div>

                                    <div class="d-flex flex-row ">
                                        <div class="align-self-center float-start">
                                            <img src="./assets/lightbulb.svg" alt="" class="icon-facts float-end">
                                        </div>
                                        <div class="ps-2 col-10 ">
                                            <p class="px-0 mb-0">{{t('health_report.wellness.general_wellness.range')}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 align-self-center">
                            <div class="justify-content-center">
                                <chart :tag="'generalWellness'" :dataRange="100" :min="40" :max="100" :data="this.getData('generalWellness').toFixed(0)" />
                            </div>
                            <div class="justify-content-center text-center">
                                <p>
                                    {{t('health_report.wellness.general_wellness.result', {value: this.getData('generalWellness').toFixed(0)})}}
                                </p>
                            </div>
                        </div>

                    </div>

                    <!-- Stress Level -->
                    <div class="px-3 py-4 color-gray flex-row d-flex row gx-3">
                            <div class="col-8">
                                <div class="d-flex flex-row justify-content-end">
                                    <div class="align-self-start">
                                        <img src="./assets/stress.svg" alt="" class="pe-0 float-end icon-vital-signs">
                                    </div>
                                    <div class="col ps-3">
                                        <div class="border-gray fw-bolder fs-5">{{t('health_report.wellness.stress.title')}}</div>
                                        <div class="text-justify">
                                            <p class="px-0">{{t('health_report.wellness.stress.definition')}}</p>
                                        </div>

                                        <div v-if="te('health_report.wellness.general_wellness.measurement')">
                                            <div class="border-gray fw-bolder fs-5">{{t('health_report.how_measured')}}</div>
                                            <div class="text-justify">
                                                <p class="px-0">{{t('health_report.wellness.stress.measurement')}}</p>
                                            </div>
                                        </div>

                                        <div class="d-flex flex-row">
                                            <div class="align-self-center float-start">
                                                <img src="./assets/lightbulb.svg" alt="" class="icon-facts float-end">
                                            </div>
                                            <div class="ps-2 col-10">
                                                <p class="px-0 mb-0">{{t('health_report.wellness.stress.range')}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4 align-self-center">
                                <div class="justify-content-center">
                                    <chart :tag="'stress'" :dataRange="5" :min="0" :max="3" :data="this.getData('stress').toFixed(1)" />
                                </div>
                                <div class="justify-content-center text-center">
                                    <p>
                                        {{t('health_report.wellness.stress.result', {value: this.getData('stress').toFixed(1)})}}
                                    </p>
                                </div>
                            </div>
                    </div>
                </div>

                <!-- Respiratory -->
                <div class="bg-light content-card mt-5 justify-content-center">
                    <div class="px-3 py-2 bg-brown">
                        <h2 class="text-light fs-3 fw-bolder px-0 align-middle">{{t('health_report.respiratory.title')}}</h2>
                    </div>

                    <!-- Respiratory Rate -->
                    <div class="px-3 py-4 color-gray flex-row d-flex row gx-3">
                        <div class="col-8">
                            <div class="d-flex flex-row justify-content-end">
                                <div class="align-self-start">
                                    <img src="./assets/respiratory-rate.svg" alt="" class="pe-0 float-end icon-vital-signs">
                                </div>
                                <div class="col ps-3">
                                    <div class="border-gray fw-bolder fs-5">{{t('health_report.respiratory.respiratory_rate.title')}}</div>
                                    <div class="text-justify">
                                        <p class="px-0">
                                            {{t('health_report.respiratory.respiratory_rate.definition')}}
                                        </p>
                                    </div>

                                    <div class="d-flex flex-row">
                                        <div class="align-self-center float-start">
                                            <img src="./assets/lightbulb.svg" alt="" class="icon-facts float-end">
                                        </div>
                                        <div class="ps-2 col-10">
                                            <p class="px-0 mb-0">
                                                {{t('health_report.respiratory.respiratory_rate.range')}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 align-self-center">
                            <div class="justify-content-center">
                                <chart :tag="'respiratoryRate'" :min="6" :max="24" :dataRange="25" :data="this.getData('respiratoryRate').toFixed(0)" :unit="' bpm'"/>
                            </div>
                            <div class="justify-content-center text-center">
                                {{t('health_report.respiratory.respiratory_rate.result', {value: this.getData('respiratoryRate').toFixed(0)})}}
                            </div>
                        </div>
                    </div>

                    <!-- Oxygen Saturation -->
                    <div class="px-3 py-4 color-gray flex-row d-flex row gx-3">
                        <div class="col-8">
                            <div class="d-flex flex-row justify-content-end">
                                <div class="align-self-start">
                                    <img src="./assets/spo2.svg" alt="" class="pe-0 float-end icon-vital-signs">
                                </div>
                                <div class="col ps-3">
                                    <div class="border-gray fw-bolder fs-5">
                                        {{t('health_report.respiratory.spo2.title')}}
                                    </div>
                                    <div class="text-justify">
                                        <p class="px-0">
                                            {{t('health_report.respiratory.spo2.definition')}}
                                        </p>
                                    </div>
                                    <div class="d-flex flex-row">
                                        <div class="align-self-center float-start">
                                            <img src="./assets/lightbulb.svg" alt="" class="icon-facts float-end">
                                        </div>
                                        <div class="ps-2 col-10">
                                            <p class="px-0 mb-0" v-html="t('health_report.respiratory.spo2.range')">
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 align-self-center">
                            <div class="justify-content-center">
                                <chart :tag="'spo2'" :dataRange="100" :min="95" :max="100" :data="this.getData('spo2').toFixed(0)" :unit="'%'"/>
                            </div>
                            <div class="justify-content-center text-center">
                                <p v-html="t('health_report.respiratory.spo2.result', {value: this.getData('spo2').toFixed(0)})">
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Facial -->
                <div class="bg-light content-card mt-5 justify-content-center">
                    <div class="px-3 py-2 bg-brown">
                        <h2 class="text-light fs-3 fw-bolder px-0 align-middle">{{t('health_report.facial.title')}}</h2>
                    </div>

                    <!-- Facial Skin Age -->
                    <div class="px-3 py-4 color-gray flex-row d-flex row gx-3">
                        <div class="col-8">
                            <div class="d-flex flex-row justify-content-end">
                                <div class="align-self-start">
                                    <img src="./assets/facial-skin-age.svg" alt="" class="pe-0 float-end icon-vital-signs">
                                </div>
                                <div class="col ps-3">
                                    <div class="border-gray fw-bolder fs-5">{{t('health_report.facial.facialSkinAge.title')}}</div>
                                    <div class="text-justify">
                                        <p class="px-0">{{t('health_report.facial.facialSkinAge.definition')}}</p>
                                    </div>

                                    <div class="border-gray fw-bolder fs-5">{{t('health_report.how_measured')}}</div>
                                    <div class="text-justify">
                                        <p class="px-0">{{t('health_report.facial.facialSkinAge.measurement')}}</p>
                                    </div>

                                    <div class="d-flex flex-row">
                                        <div class="align-self-center float-start">
                                            <img src="./assets/lightbulb.svg" alt="" class="icon-facts float-end">
                                        </div>
                                        <div class="ps-2 col-10">
                                            <p class="px-0 mb-0">
                                                {{t('health_report.facial.facialSkinAge.range')}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 align-self-center">
                            <div class="text-center age-number">
                                {{ this.getData('facialSkinAge').toFixed(0) }}
                            </div>
                            <div class="justify-content-center text-center">
                                <p>
                                    {{ t('health_report.facial.facialSkinAge.result') }}
                                </p>
                            </div>
                        </div>
                    </div>
                    
                </div>

                <!-- <div class="row justify-content-center my-5">
                    <img src="./assets/mannings-logo.svg" alt="" class="w-75">
                </div> -->
                <div class="row justify-content-center mt-5">
                    <div class="fs-6 text-justify">
                        <p>{{t('health_report.disclaimer.title')}}:</p>
                        <p v-html="t('health_report.disclaimer.p1')"></p>
                        <p>{{t('health_report.disclaimer.p2')}}</p>
                    </div>
                </div>
                <div class="row pb-5 pt-4">
                    <img src="./assets/panoptic.png" alt="" class="panoptic-logo">
                </div>
                <!-- copyright message -->
                <div class="copyright text-center">{{ t("copyright") }}<br/></div>
            </div>
        </div>
        <side-bar />
        <div class="pdf">
            <report-pdf ref="pdf"/>
        </div>
    </div>
</template>


<script>
import { useI18n } from 'vue-i18n';
import { newUserManager } from "../../user/company-user-manager.ts";
import ReportPdf from "./report-pdf.vue"
import SideBar from '../sidebar/sidebar.vue';
import { useRouter } from 'vue-router'
import Chart from "./chart.vue";
import { jsPDF } from "jspdf";
import * as html2canvas from 'html2canvas'; 
import { VitalSignEngine } from "vital-sign-engine";
import { inject } from "vue";
import Feedback from '../feedback/feedback-page-satisfactory.vue'
import Logger from '@/common/logger';
// import Copyright from '../../common/copyright.vue';

export default {
    name: "email-page",
    setup() {
        const { t, te, locale } = useI18n();
        const router = useRouter();
        const {profileFilled} = inject('profileFilled')
        var currentUser = newUserManager.currentUser;
        
        // if(!currentUser || !currentUser.currentUser.lastVitalSigns || !profileFilled) {
        if(!currentUser) {
            router.push('/')
        }

        return {
            t, te, locale, 
            currentUser,
            profileFilled
        }
    },
    components: {
        SideBar,
        Chart,
        ReportPdf,
        Feedback,
        // Copyright,
    },
    data() {
        return {
            sendingEmail: false,
        }
    },
    created() {
        if ( this.$root.$data.debugMode ) {
            let currentUser = newUserManager.currentUser ? newUserManager.currentUser.currentUser : null;
            if ( currentUser && !currentUser.lastHealth) {
                if (!currentUser.email) {
                    currentUser._genUserInfo();
                    Logger.log("%c<email-page debug>generated user info", "color:magenta")
                }
                if (!currentUser.lastHealth) {
                    currentUser._genLastHealth();
                    Logger.log("%c<email-page debug>generated last health", "color:magenta")
                }
                Logger.log("%cgenerated currentUser: ", "color:magenta", currentUser)
            }
        } else {
            if(!this.currentUser || !this.currentUser.currentUser.lastVitalSigns || !this.profileFilled) {
                this.$router.push('/');
            }
        }
    },
    computed: {
        userName() {
            if (!this.currentUser) {
                return "";
            }
            if (this.currentUser.currentUser.name == 'Guest') {
                return this.t("user.guest")
            }
            return this.currentUser.currentUser.name;
        },
        age() {            
            if (!this.currentUser) {
                return "";
            }
            const age = this.currentUser.currentUser.userSettings.age;
            if (age) { return age; }
            const dateString = this.currentUser.currentUser.userSettings.birthday;
            var ageInMilliseconds = new Date() - new Date(dateString);
            return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365);
        },
        lastScanTime() {
            if(!this.currentUser.currentUser.lastVitalSigns) {
                return;
            }
            const d = new Date(this.currentUser.currentUser.lastVitalSigns.datetime);
            let month = String(d.getMonth() + 1);
            let day = String(d.getDate());
            const year = String(d.getFullYear());

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return `${day}/${month}/${year}`;
        }
    },
    methods: {
        sendToEmail() {
            this.sendingEmail = true;

            let pdfRef1 = this.$refs.pdf.$refs.pdf_1;
            let pdfRef2 = this.$refs.pdf.$refs.pdf_2;
            let pdf = new jsPDF('p', 'mm');

            html2canvas(pdfRef1, {allowTaint:true,
                  onclone: function (clonedDoc) {
                    clonedDoc.getElementById('pdf-1').style.display = 'inline-block';
                }
            }).then(canvas1 => {
                let pdfImage1 = canvas1.toDataURL();

                pdf.addImage(pdfImage1, 'PNG', 0, 0, 210, 297, "alias1", 'FAST');

                pdf.addPage();

                html2canvas(pdfRef2, {allowTaint:true,
                    onclone: function (clonedDoc) {
                        clonedDoc.getElementById('pdf-2').style.display = 'inline-block';
                    }
                }).then(canvas => {
                    let pdfImage = canvas.toDataURL();

                    pdf.addImage(pdfImage, 'PNG', 0, 0, 210, 297, "alias2", 'FAST');

                    // base64 generation
                    let base64Report = pdf.output('datauristring').split('base64,')[1];
                    let currentUser = newUserManager.currentUser.currentUser;
                    if (currentUser) {
                        let emailTemplate = {
                            emailContent: this.t('email_template.email_content', {username: currentUser.name}),
                            emailSubject: this.t('email_template.email_subject'),
                            fileName: this.t('email_template.file_name')
                        }
                        
                        VitalSignEngine.sendHealthReport([currentUser.userSettings.email], base64Report, emailTemplate, "no-reply@panoptic.ai");
                        this.sendingEmail = false;
                        alert(`${this.t('message.email')} ${currentUser.name} @ {${currentUser.userSettings.email}}`);
                    } else {
                        alert("<Email-page> Something is Wrong.");
                    }
                    
                    // pdf.save(`Health Report - ${this.currentUser.currentUser.name}`);
                })
            })
        },
        getData(tag) {
            if (this.currentUser.currentUser.lastVitalSigns && this.currentUser.currentUser.lastVitalSigns[tag]) {
                return this.currentUser.currentUser.lastVitalSigns[tag];
            }
            else {
                return 0;
            }
        }
    }
}
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,400;0,700;0,800;1,300&display=swap');

.bg-brown {
    background: #A5915C;
    border-radius: 10px 10px 0px 0px;
}

.border-gray {
    border-bottom:solid;
    border-bottom-color: #5B636B;
}

.general-score {
    background-color: #EEEFF3;
    border-radius: 9px;
    padding: 15px 5px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.color-brown {
    color: #A5915C;
}

.color-gray {
    color: #747579;
}

.container-full {
  padding: 30px;
  height: 100vh;
}
.email-button {
    display: flex;
    justify-content: flex-end;
}
.email-message {
    color: red;
    margin: auto 6px;
}
.content {
  position: static;
  padding-left: 87px;
  right: 0px;
  height: 100vh;
  min-height: max-content;
  background: #e4e3e3;
  overflow-y: scroll;
  font-size: large;
}
.content-card {
    border-radius: 10px;
    box-shadow: 4px 4px 4px 0px #00000040;
}

.font-sarabun {
    font-family: 'Sarabun';
}

.icon {
    width: 22px;
    height: 30px;
}

.icon-facts {
    width: 30px;
}

.icon-profile-vitals {
    width: 50px;
    height: 50px;
}

.icon-vital-signs {
    width: 80px;
}

#logo {
    float: right;
    height: 30px;
}

#profile-img {
  margin: auto auto;
  margin-bottom: 5px;
  max-width:60%;
}
.age-number {
    color: #A5915C;
    font-size: xxx-large;
    font-weight: bold;
}
.text-justify {
    text-align: justify;
}
.copyright {
  position: relative;
  font-size: 14px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 0;
  background-color: #e4e3e3;
}
.panoptic-logo {
    width: 300px;
    padding: 0;
}

@media (max-width: 768px) {
  .content {
    padding-left: 0px;
    margin-bottom: 50px;
  }
}

.pdf {
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
    pointer-events: none;
}
</style>