<template>
    <h1 class='display-5' id="operation">
        {{t('help_content.operation.title')}}
    </h1>
    <h2 class="fs-2 fw-normal mt-5 mb-4" id="contact-based-device">
        {{t('help_content.operation.ground_truth.title')}}
    </h2>
    <figure class="figure mx-auto d-block">
        <img src="./assets/contact-based-device.png" class="figure-img mx-auto img-fluid d-block" alt="Contact-Based Device Scanning">
    </figure>
    <p>        
        {{t('help_content.operation.ground_truth.p1')}}
        <br><br>
        {{t('help_content.operation.ground_truth.p2')}}
        <br>
        <ol>
            <li>{{t('help_content.operation.ground_truth.1')}}</li>
            <li>{{t('help_content.operation.ground_truth.2')}}</li>
            <li>{{t('help_content.operation.ground_truth.3')}}</li>
            <li>{{t('help_content.operation.ground_truth.4')}}</li>
        </ol>
    </p>
    <p v-html="t('help_content.operation.ground_truth.p3')"></p>
    <h2 class="fs-2 fw-normal mt-5 mb-4" id="scanning-system">
        {{t('help_content.operation.scanning.title')}}
    </h2>
    <p>
        {{t('help_content.operation.scanning.p1')}}
        <br>
        <ol>
            <li>
                <p>
                    {{t('help_content.operation.scanning.1')}}
                </p>
                <figure>
                    <div class="row">
                        <img src="./assets/step-1-1.png" class="figure-img mx-auto img-fluid d-block col-6" alt="">
                        <img src="./assets/step-1-2.jpg" class="figure-img mx-auto img-fluid d-block col-6" alt="">
                    </div>
                    <figcaption class="figure-caption text-center fst-italic">{{t('help_content.operation.scanning.laptop')}}</figcaption>
                </figure>
                <figure>
                    <div class="row">
                        <img src="./assets/step-1-3.jpg" class="figure-img mx-auto img-fluid d-block col-6" alt="">
                        <img src="./assets/step-1-4.jpg" class="figure-img mx-auto img-fluid d-block col-6" alt="">
                    </div>
                    <figcaption class="figure-caption text-center fst-italic">{{t('help_content.operation.scanning.phone')}}</figcaption>
                </figure>
                <figure>
                    <div class="row">
                        <img src="./assets/step-1-5.png" class="figure-img mx-auto img-fluid d-block col-6" alt="">
                        <img src="./assets/step-1-6.jpg" class="figure-img mx-auto img-fluid d-block col-6" alt="">
                    </div>
                    <figcaption class="figure-caption text-center fst-italic">{{t('help_content.operation.scanning.pc')}}</figcaption>
                </figure>
            </li>
            <li>
                <p v-html="t('help_content.operation.scanning.2')">
                </p>
                <figure class="figure mx-auto d-block">
                    <img src="./assets/starting-up.png" class="figure-img mx-auto img-fluid d-block" alt="Starting Up The Vital Sign Scanner">
                    <img src="./assets/camera-prompt.png" class="figure-img mx-auto img-fluid d-block" alt="">
                    <figcaption class="figure-caption text-center fst-italic">{{t('help_content.operation.scanning.caption_2')}}</figcaption>
                </figure>
            </li>
            <li>
                <p v-html="t('help_content.operation.scanning.3')">
                </p>
                <figure class="figure mx-auto d-block">
                    <img src="./assets/step-3-1.jpg" class="figure-img mx-auto img-fluid d-block w-100" alt="Powered Up System">
                    <img src="./assets/step-3-2.png" class="figure-img mx-auto img-fluid d-block" alt="Powered Up System">
                    <img src="./assets/powered-up-system.png" class="figure-img mx-auto img-fluid d-block" alt="Powered Up System">
                    <figcaption class="figure-caption text-center fst-italic">{{t('help_content.operation.scanning.caption_3')}}</figcaption>
                </figure>
            </li>
            <li>
                <p v-html="t('help_content.operation.scanning.4')">
                </p>
                <figure class="figure mx-auto d-block">
                    <img src="./assets/scanning-conditions.png" class="figure-img mx-auto img-fluid d-block" alt="Conditions For Scanning">
                    <figcaption class="figure-caption text-center fst-italic">{{t('help_content.operation.scanning.caption_4')}}</figcaption>
                </figure>
            </li>
            <li>
                <p v-html="t('help_content.operation.scanning.5')">
                </p>
                <figure class="figure mx-auto d-block">
                    <img src="./assets/scanning-started.png" class="figure-img mx-auto img-fluid d-block" alt="Scanning Started">
                    <figcaption class="figure-caption text-center fst-italic">{{t('help_content.operation.scanning.caption_5')}}</figcaption>
                </figure>
                <p class="fst-italic">
                    {{t('help_content.operation.scanning.note_5')}}
                </p>
            </li>
            <li>
                <p v-html="t('help_content.operation.scanning.6')">
                </p>
                <figure class="figure mx-auto d-block">
                    <img src="./assets/scanning-finished-2.png" class="figure-img mx-auto img-fluid d-block" alt="Scanning Finished">
                    <img src="./assets/scanning-finished.png" class="figure-img mx-auto img-fluid d-block" alt="Scanning Finished">
                    <figcaption class="figure-caption text-center fst-italic">{{t('help_content.operation.scanning.caption_6')}}</figcaption>
                </figure>
            </li>
            <li>
                <p v-html="t('help_content.operation.scanning.7')">
                </p>
                <figure class="figure mx-auto d-block">
                    <img src="./assets/result-overview.png" class="figure-img mx-auto img-fluid d-block" alt="Scanning Results Overview">
                    <figcaption class="figure-caption text-center fst-italic">{{t('help_content.operation.scanning.caption_7')}}</figcaption>
                </figure>
            </li>
        </ol>
    </p>
</template>

<script>
import {useI18n} from 'vue-i18n';
export default {
    setup() {
        const {t, locale} = useI18n();
        return {t, locale}
    },
}
</script>

<style scoped>
p, li {
    text-align: justify;
}
a {
    text-decoration: none;
}
</style>