<template>
    <div id="pdf-1" ref='pdf_1' v-if="currentUser && currentUser.currentUser.lastVitalSigns && profileFilled">
        <div class="row mb-5">
            <h1 class="fw-bolder color-brown" :style="{'font-size': '80px'}">
                {{t('health_report.title')}}
            </h1>
        </div>
        <div class="d-flex flex-row bg-light p-5 content-card fw-bold my-5 justify-content-center">
            <div class="col-5 color-gray" :style="{'font-size': '45px'}">
                <div class="mb-2">{{t('health_report.age')}}: {{ age }}</div>
                <div class="my-2">{{t('health_report.sex')}}: {{ currentUser ? t('health_report.'+currentUser.currentUser.userSettings.gender) : ""}}</div>
                <div class="text-break">
                    {{t('health_report.email')}}: {{currentUser ? currentUser.currentUser.userSettings.email : ""}}
                </div>
            </div>
            
            <div class="col-7">
                <div class="d-flex flex-row color-gray align-items-center h-100">
                    <div class="col-6 pe-3">
                        <div class="general-score">
                            <div class="row justify-content-center text-center" :style="{'font-size': '50px'}">
                                {{t('health_report.wellness.stress.title')}}                                
                            </div>
                            <div class="row justify-content-center mt-2 mb-3 color-brown"  :style="{'font-size': '80px'}">
                                <img src="./assets/stress-top.png" alt="covid" class="ps-0 me-5 icon-profile-vitals" >
                                {{this.getData('stress').toFixed(1) }}
                            </div>
                        </div>
                    </div>
                    <div class="col-6 ps-3">
                        <div class="general-score">
                            <div class="row justify-content-center text-center" :style="{'font-size': '50px'}">
                                {{t('health_report.general_wellness')}}
                            </div>
                            <div class="row justify-content-center mt-2 mb-3 color-brown"  :style="{'font-size': '80px'}">
                                <img src="./assets/general-wellness.png" alt="covid" class="ps-0 me-5 icon-profile-vitals" :style="{'height': '130', 'width': '130px'}">
                                {{ this.getData('generalWellness').toFixed(1) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Wellness -->
        <div class="bg-light content-card my-5 justify-content-center">
            <div class="px-5 py-2 bg-brown">
                <h2 class="text-light fw-bolder px-0 align-middle" :style="{'font-size': '60px'}">{{t('health_report.wellness.title')}}</h2>
            </div>

            <!-- General Wellness -->
            <div class="row px-3 py-4 color-gray justify-content-center">
                <div class="row gx-5">
                    <div class="col-8" :style="{'padding-right' : '50px'}">
                        <div class="row justify-content-end">
                            <div class="col-2 align-self-start">
                                <img src="./assets/general-wellness-2.png" alt="" class="pe-0 float-end icon-vital-signs">
                            </div>
                            <div class="col ps-5">
                                <div class="row border-gray fw-bolder" :style="{'font-size': '45px'}">{{t('health_report.wellness.general_wellness.title')}}</div>
                                <div class="row text-justify" :style="{'font-size': '35px'}">
                                    <p class="px-0">{{t('health_report.wellness.general_wellness.definition')}}</p>
                                </div>

                                <div v-if="te('health_report.wellness.general_wellness.measurement')">
                                    <div class="row border-gray fw-bolder" :style="{'font-size': '45px'}">{{t('health_report.how_measured')}}</div>
                                    <div class="row text-justify" :style="{'font-size': '35px'}">
                                        <p class="px-0">{{t('health_report.wellness.general_wellness.measurement')}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-end my-3">
                            <div class="col-10 ps-5">
                                <span class="row text-justify"  :style="{'font-size': '35px'}">
                                    <div class="col-1">
                                        <img src="./assets/lightbulb.png" alt="" class="icon-facts float-end">
                                    </div>
                                    <div class="col">
                                        {{t('health_report.wellness.general_wellness.range')}}
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 align-self-center">
                        <div class="row justify-content-center">
                            <chart :tag="'generalWellness'" :dataRange="100" :min="40" :max="100" :data="this.getData('generalWellness').toFixed(0)" :pdf="true"/>
                        </div>
                        <div class="row justify-content-center text-center" :style="{'font-size': '35px'}">
                            <p>
                                {{t('health_report.wellness.general_wellness.result', {value: this.getData('generalWellness').toFixed(0)})}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Stress Level -->
            <div class="row px-3 py-4 color-gray justify-content-center">
                <div class="row gx-5">
                    <div class="col-8" :style="{'padding-right' : '50px'}">
                        <div class="row justify-content-end">
                            <div class="col-2 align-self-start">
                                <img src="./assets/stress.png" alt="" class="pe-0 float-end icon-vital-signs">
                            </div>
                            <div class="col ps-5">
                                <div class="row border-gray fw-bolder" :style="{'font-size': '45px'}">{{t('health_report.wellness.stress.title')}}</div>
                                <div class="row text-justify" :style="{'font-size': '35px'}">
                                    <p class="px-0">{{t('health_report.wellness.stress.definition')}}</p>
                                </div>

                                <div v-if="te('health_report.wellness.general_wellness.measurement')">
                                    <div class="row border-gray fw-bolder" :style="{'font-size': '45px'}">{{t('health_report.how_measured')}}</div>
                                    <div class="row text-justify" :style="{'font-size': '35px'}">
                                        <p class="px-0">{{t('health_report.wellness.stress.measurement')}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-end my-3">
                            <div class="col-10 ps-5">
                                <span class="row text-justify"  :style="{'font-size': '35px'}">
                                    <div class="col-1">
                                        <img src="./assets/lightbulb.png" alt="" class="icon-facts float-end">
                                    </div>
                                    <div class="col">
                                        {{t('health_report.wellness.stress.range')}}
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 align-self-center">
                        <div class="row justify-content-center">
                            <chart :tag="'stress'" :dataRange="5" :min="0" :max="3" :data="this.getData('stress').toFixed(1)" :pdf="true"/>
                        </div>
                        <div class="row justify-content-center text-center" :style="{'font-size': '35px'}">
                            <p>
                                {{t('health_report.wellness.stress.result', {value: this.getData('stress').toFixed(1)})}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Respiratory -->
        <div class="bg-light content-card mt-5 mb-3 justify-content-center">
            <div class="px-5 py-2 bg-brown">
                <h2 class="text-light fw-bolder px-0 align-middle" :style="{'font-size': '60px'}">{{t('health_report.respiratory.title')}}</h2>
            </div>

            <!-- Respiratory Rate -->
            <div class="row px-3 py-4 color-gray justify-content-center">
                <div class="row gx-5">
                    <div class="col-8" :style="{'padding-right' : '50px'}">
                        <div class="row justify-content-end">
                            <div class="col-2 align-self-start">
                                <img src="./assets/respiratory-rate.png" alt="" class="pe-0 float-end icon-vital-signs">
                            </div>
                            <div class="col ps-5">
                                <div class="row border-gray fw-bolder" :style="{'font-size': '45px'}">{{t('health_report.respiratory.respiratory_rate.title')}}</div>
                                <div class="row text-justify" :style="{'font-size': '35px'}">
                                    <p class="px-0">{{t('health_report.respiratory.respiratory_rate.definition')}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-end my-3">
                            <div class="col-10 ps-5">
                                <div class="row text-justify"  :style="{'font-size': '35px'}">
                                    <div class="col-1">
                                        <img src="./assets/lightbulb.png" alt="" class="icon-facts float-end">
                                    </div>
                                    <div class="col">
                                        {{t('health_report.respiratory.respiratory_rate.range')}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 align-self-center">
                        <div class="row justify-content-center">
                            <chart :tag="'respiratoryRate'" :min="6" :max="24" :dataRange="25" :data="this.getData('respiratoryRate').toFixed(0)" :unit="' bpm'" :pdf="true"/>
                        </div>
                        <div class="row justify-content-center text-center" :style="{'font-size': '35px'}">
                            <p>
                                {{t('health_report.respiratory.respiratory_rate.result', {value: this.getData('respiratoryRate').toFixed(0)})}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Oxygen Saturation -->
            <div class="row px-3 py-4 color-gray justify-content-center">
                <div class="row gx-5">
                    <div class="col-8" :style="{'padding-right' : '50px'}">
                        <div class="row justify-content-end">
                            <div class="col-2 align-self-start">
                                <img src="./assets/spo2.png" alt="" class="pe-0 float-end icon-vital-signs">
                            </div>
                            <div class="col ps-5">
                                <div class="row border-gray fw-bolder" :style="{'font-size': '45px'}">{{t('health_report.respiratory.spo2.title')}}</div>
                                <div class="row text-justify" :style="{'font-size': '35px'}">
                                    <p class="px-0">{{t('health_report.respiratory.spo2.definition')}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-end my-3">
                            <div class="col-10 ps-5">
                                <span class="row text-justify"  :style="{'font-size': '35px'}">
                                    <div class="col-1">
                                        <img src="./assets/lightbulb.png" alt="" class="icon-facts float-end">
                                    </div>
                                    <div class="col" v-html="t('health_report.respiratory.spo2.range')">
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 align-self-center">
                        <div class="row justify-content-center">
                            <chart :tag="'spo2'" :dataRange="100" :min="95" :max="100" :data="this.getData('spo2').toFixed(0)" :unit="'%'" :pdf="true"/>
                        </div>
                        <div class="row justify-content-center text-center" :style="{'font-size': '35px'}">
                            <p v-html="t('health_report.respiratory.spo2.result', {value: this.getData('spo2').toFixed(0)})">
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center" :style="{'padding-top' : '20px'}">
            <div class="text-justify"  :style="{'font-size': '25px'}">
                <p>{{t('health_report.disclaimer.title')}}:</p>
                <p v-html="t('health_report.disclaimer.p1')"></p>
                <p>{{t('health_report.disclaimer.p2')}}</p>
            </div>
        </div>
        <div class="row pt-4">
            <img src="./assets/panoptic.png" alt="" class="panoptic-logo">
        </div>
    </div>

    <div id="pdf-2" ref="pdf_2">        
        <div class="row mb-5">
            <h1 class="fw-bolder color-brown" :style="{'font-size': '80px'}">
                {{t('health_report.title')}}
            </h1>
        </div>

        <!-- Facial -->
        <div class="bg-light content-card mt-5 justify-content-center">
            <div class="px-5 py-2 bg-brown">
                <h2 class="text-light fw-bolder px-0 align-middle" :style="{'font-size': '60px'}">{{t('health_report.facial.title')}}</h2>
            </div>

            <!-- Facial Skin Age -->
            <div class="px-3 py-4 color-gray justify-content-center">
                <div class="row gx-5">
                    <div class="col-8" :style="{'padding-right' : '50px'}">
                        <div class="row justify-content-end">
                            <div class="col-2 align-self-start">
                                <img src="./assets/facial-skin-age.png" alt="" class="pe-0 float-end icon-vital-signs">
                            </div>
                            <div class="col ps-5">
                                <div class="row border-gray fw-bolder" :style="{'font-size': '45px'}">{{t('health_report.facial.facialSkinAge.title')}}</div>
                                <div class="row text-justify" :style="{'font-size': '35px'}">
                                    <p class="px-0">{{t('health_report.facial.facialSkinAge.definition')}}</p>
                                </div>


                                <div class="row border-gray fw-bolder" :style="{'font-size': '45px'}">{{t('health_report.how_measured')}}</div>
                                <div class="row text-justify" :style="{'font-size': '35px'}">
                                    <p class="px-0">{{t('health_report.facial.facialSkinAge.measurement')}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-end my-3">
                            <div class="col-10 ps-5">
                                <div class="row text-justify"  :style="{'font-size': '35px'}">
                                    <div class="col-1">
                                        <img src="./assets/lightbulb.png" alt="" class="icon-facts float-end">
                                    </div>
                                    <div class="col">
                                        {{t('health_report.facial.facialSkinAge.range')}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 align-self-center">
                        <div class="row justify-content-center age-number">
                            {{ this.getData('facialSkinAge').toFixed(0) }}
                        </div>
                        <div class="row justify-content-center text-center" :style="{'font-size': '35px'}">
                            <p>
                                {{ t('health_report.facial.facialSkinAge.result') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center" :style="{'padding-top' : '20px'}">
            <div class="text-justify"  :style="{'font-size': '25px'}">
                <p>{{t('health_report.disclaimer.title')}}:</p>
                <p v-html="t('health_report.disclaimer.p1')"></p>
                <p>{{t('health_report.disclaimer.p2')}}</p>
            </div>
        </div>
        <div class="row pt-4">
            <img src="./assets/panoptic.png" alt="" class="panoptic-logo">
        </div>
    </div>
</template>

<script>
import { inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { newUserManager } from "../../user/company-user-manager.ts";
import { useRouter } from 'vue-router'
import Chart from "./chart.vue";
// import Copyright from '../../common/copyright.vue';

export default {
    name: "email-page",
    setup() {
        const { t, te, locale } = useI18n();
        const router = useRouter();
        var currentUser = newUserManager.currentUser;
        const {profileFilled } = inject('profileFilled');
        
        // if(!currentUser || !currentUser.currentUser.lastVitalSigns || !profileFilled) {
        if(!currentUser) {
            router.push('/')
        }

        return {
            t, te, locale,
            currentUser,
            profileFilled
        }
    },
    components: {
        Chart,
    },
    created() {
        if ( this.$root.$data.debugMode ) {
            // pass            
        } else {
            if(!this.currentUser || !this.currentUser.currentUser.lastVitalSigns || !this.profileFilled) {
                this.$router.push('/');
            }
        }
    },
    computed: {
        userName() {
            if (!this.currentUser) {
                return "";
            }
            if (this.currentUser.currentUser.name == 'Guest') {
                return this.t("user.guest")
            }
            return this.currentUser.currentUser.name;
        },
        age() {            
            if (!this.currentUser) {
                return "";
            }
            const age = this.currentUser.currentUser.userSettings.age;
            if (age) { return age; }
            const dateString = this.currentUser.currentUser.userSettings.birthday;
            var ageInMilliseconds = new Date() - new Date(dateString);
            return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365);
        },
        lastScanTime() {
            const d = new Date(this.currentUser.currentUser.lastVitalSigns.datetime);
            let month = String(d.getMonth() + 1);
            let day = String(d.getDate());
            const year = String(d.getFullYear());

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return `${day}/${month}/${year}`;
        }
    },
    methods: {
        getData(tag) {
            if (this.currentUser && this.currentUser.currentUser.lastVitalSigns && this.currentUser.currentUser.lastVitalSigns[tag]) {
                return this.currentUser.currentUser.lastVitalSigns[tag];
            }
            else {
                return 0;
            }
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,400;0,700;0,800;1,300&display=swap');

#pdf-1 {
    width: 2480px;
    min-height: 3508px;
    padding: 100px;
    background-color: white;
    transform: scale(0.5);
}
#pdf-2 {
    width: 2480px;
    height: 3508px;
    padding: 100px;
    background-color: white;
    transform: scale(0.5);
}

.bg-brown {
    background: #A5915C;
    border-radius: 40px 40px 0px 0px;
}

.border-gray {
    border-bottom:solid;
    border-bottom-color: #5B636B;
}

.general-score {
    background-color: #EEEFF3;
    border-radius: 40px;
    padding: 5px;
    height: 100%;
}

.color-brown {
    color: #A5915C;
}

.color-gray {
    color: #747579;
}

.container-full {
  padding: 50px;
  height: 100vh;
}

.content {
  position: static;
  padding-left: 87px;
  right: 0px;
  height: 100vh;
  min-height: max-content;
  background: #e4e3e3;
  overflow-y: scroll;
  font-size: large;
}
.content-card {
    border-radius: 40px;
    /* box-shadow: 4px 4px 4px 0px #00000040; */
}

.font-sarabun {
    font-family: 'Sarabun';
}

.icon {
    width: 70px;
    height: 70px;
    margin-right: 30px;
}

.icon-facts {
    width: 50px;
    height: 60px;
}

.icon-profile-vitals {
    width: 100px;
    height: 130px;
}

.icon-vital-signs {
    width: 200px;
}
.panoptic-logo {
    width: 500px;
    padding: 0;
}

#logo {
    float: right;
    height: 30px;
}

#profile-img {
  margin: auto auto;
  margin-bottom: 5px;
  max-width:60%;
}
.age-number {
    color: #A5915C;
    font-size: 120px;
    font-weight: 500;
}
.text-justify {
    text-align: justify;
}

@media (max-width: 768px) {
  .content {
    padding-left: 0px;
    margin-bottom: 50px;
  }
}
</style>